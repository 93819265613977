<template>
    <div class="search-me">

        <input type="search" id="exp-search" name="search" placeholder="Search" class="in-search" :class="{ 'border-bottom-left-radius-zero border-bottom-right-radius-zero' : search_results.length }" v-model="string">

        <svg class="circular small" viewBox="25 25 50 50" v-if="uicontrol.loading">
            <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="4" stroke-miterlimit="10" />
        </svg>

        <div class="message-center search_results" v-if="search_results.length">
            <component :is="search_result.type" :search_result="search_result" v-for="search_result in search_results" :key="search_result.url"></component>
        </div>
    </div>
</template>
<script>

    import experiment_result from './results/ExperimentResult';
    import experiment from './results/Experiment';
    import empty from './results/Empty.vue';

    export default {

        name: 'Search',

        components: {
            empty, experiment_result, experiment
        },

        props: [
            'category'
        ],

        data: function () {

            return {
                pickrs: {},
                string: '',
                search_results: [],
                uicontrol: {
                    loading: false
                },
                empty_result: [
                    {
                        model: 'empty',
                        title: 'No results'
                    }
                ],
            }

        },

        mounted() {

        },

        watch : {

            string : function(thestring, theoldstring){

                this.uicontrol.loading = (thestring.length > 0) ? true : false;

                if(thestring.length >= 3){
                    this.search();
                }else{
                    this.search_results = [];
                }

            }

        },

        methods: {

            search: _.debounce(function () {

                    var vm = this;

                    // Preventspinner
                    vm.$root.active_http_requests--;

                    vm.uicontrol.loading = true;

                    axios.post('/search/' + vm.category, {
                        'string': vm.string,
                    }).then(response => {

                        console.log(response.data);

                        // Populate list of results
                        vm.search_results = (response.data.hits.length) ? response.data.hits : vm.empty_result;

                        // Hide spinner
                        vm.uicontrol.loading = false;
                        return true;

                    }).finally(() => {

                        vm.uicontrol.loading = false;

                    })

                },

                500 // debounce time

            )

        }

    }


</script>
