<template>

    <main class="mb-4">

        <channel
            :channel="`experiment_results.${result_identifier}`"
            :channel_type="'private'"
            :commenting_enabled="can_participate"
            :scroll_bottom="false"
            >
        </channel>

    </main>

</template>

<script>

    import channel from '@/components/chat/Channel';

    export default {
        name: "MessagingChannel",

        components : {
            channel
        },

        props : ['result_identifier', 'tab', 'field', 'val', 'can_participate'],

        computed : {

        }
    }

</script>

<style scoped>

</style>
