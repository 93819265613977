<template>
    <div class="card">
        <div class="card-body">
            <div>
                <button class="options edit-btn" @click="$emit('edit', index)"></button>
                <h3 class="card-title" v-text="short_title"></h3>
                <p v-text="short_description" class="description"></p>
            </div>
            <a :href="`/dashboard/communications/${post.category_slug}/${post.slug}`" class="btn btn-secondary btn-sm btn-white">Read</a>
        </div>
    </div>
</template>

<script>

    export default {
        name: "FullWidthNoImage",
        data(){
            return {

            }
        },

        props : ['post', 'categorySlug', 'index'],

        computed : {

            short_title() {
               
                let desired_length = 100;

                return (this.post.data.title.length <= desired_length) ? this.post.data.title : this.post.data.title.substr(0, desired_length) + "..."; 
            },

            short_description(){

                let desired_length = 210;
                return (this.post.data.short_description.length <= desired_length) ? this.post.data.short_description : this.post.data.short_description.substr(0, desired_length) + "...";

            }

        },

        methods : {

            imgUrl(string){

                if(string == null)
                    return '';

                return string.includes('http') ? string : '/file/' + this.post.data.image;

            }

        }
    }

</script>

<style scoped>

</style>
