<template>
    <div class="form-group row">
        <label class="col-2 text-grey" for="" v-text="field.label"></label>
        <template v-if="editable">
            <div class="col-11">
                <input class="form-control" type="text" placeholder="Enter text" v-model="field_value" >
            </div>
        </template>
        <template v-else>
             <div class="col-10">
                <p>{{field_value}}</p>
             </div>
        </template>
    </div>
</template>

<script>
    export default {
        name: "InputTextComponent",
        props : ['tab', 'field', 'val', 'editable'],

        computed : {


            field_value : {

                get : function(){
                    return this.val;
                },

                set : function(newVal){
                    this.$emit('update', {
                        tab : this.tab,
                        field : this.field,
                        value : newVal
                    });
                }

            }


        }
    }
</script>
