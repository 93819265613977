<template>
    <div class="result">
        <div class="line fullwidth d-flex justify-content-between">
            <span class="text-center result-title" v-text="search_result.title"></span>
            <a :href="search_result.url">Open</a>
        </div>
        <div class="subline">
            <p class="small mb-2">Experiment Result</p>
        </div>
    </div>
</template>

<script>
    export default {
        name : 'ExperimentResult',
        props : ['search_result'],
        data : function(){
            return {

            }
        }
    }
</script>
