import { render, staticRenderFns } from "./MessagingChannel.vue?vue&type=template&id=09c18fd6&scoped=true&"
import script from "./MessagingChannel.vue?vue&type=script&lang=js&"
export * from "./MessagingChannel.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09c18fd6",
  null
  
)

export default component.exports