<template>
    <div class="t-row" v-if="first_month_millis && last_month_millis">
        <h5 class="sq name">
            {{report.experiment.laboratory.name}}
            <p>{{ report.experiment.type_name }}</p>
        </h5>
        <div class="sq studies">
            <p>{{ report.title }}</p>
        </div>
        <div class="sq">
            <p>{{ report.data.Overview_host }}</p>
            <p>{{ report.data.Overview_virus_name }}</p>
        </div>
        <div class="sq time">
            <div class="progress-time">
                <span class="start" :style="{ 'left' : `calc(${getXAxisAsPercent(report.start_date)}% - 6px)`}" data-toggle="tooltip" data-placement="top" :title="'Started: ' + formatTimeStamp(report.start_date)" v-if="isInInterval(report.start_date)"></span>
                <span class="duration" :style="{ 'left' : getXAxisAsPercent(report.start_date) + '%', 'width' : (getXAxisAsPercent(report.end_date) - getXAxisAsPercent(report.start_date)) + '%' }"></span>
                <span class="scheduled-end" :style="{ 'left' : `calc(${getXAxisAsPercent(report.end_dates[0].timestamp)}% - 6px)`}" v-if="showExpected(report) && isInInterval(report.end_dates[0].timestamp)" data-toggle="tooltip" data-placement="top" :title="'Scheduled end: ' + formatTimeStamp(report.end_dates[0].timestamp)"></span>

                <template v-if="isInInterval(report.end_date)">
                    <span class="expected" :style="{ 'left' : `calc(${getXAxisAsPercent(report.end_date)}% - 6px)`}" data-toggle="tooltip" data-placement="top" :title="'Expected: ' + formatTimeStamp(report.end_date)" v-if="!report.signed_by_user_id"></span>
                    <span class="completed" :style="{ 'left' : `calc(${getXAxisAsPercent(report.end_date)}% - 6px)`}" data-toggle="tooltip" data-placement="top" :title="'Completed: ' + formatTimeStamp(report.end_date)" v-else></span>
                </template>

                <div class="dashed-line" :style="{ 'margin-left' : `calc(${getXAxisAsPercent(report.end_dates[0].timestamp)}% - 6px)`}"></div>
            </div>
            <div class="bg-lines">
                <span class="line" v-for="month in months"></span>
            </div>
        </div>
        <div class="sq open">
            <a :href="'/dashboard/science/experiments/' + report.experiment.identifier + '/results/' + report.identifier">Open</a>
        </div>
    </div>


</template>

<script>

import { DateTime } from "luxon";

export default {
    name: "Report",
    props : ['report', 'months'],
    data : function(){
      return {
          first_month_millis : null,
          last_month_millis : null,
      }
    },

    mounted(){
        this.first_month_millis = this.months[0].toMillis();
        this.last_month_millis = this.months[this.months.length - 1].toMillis();

        // Enable tooltips for newly created DOM elements
        setTimeout(function(){
            $('[data-toggle="tooltip"]').tooltip();
        }, 500);
    },

    methods : {

        getXAxisAsPercent(point_timestamp){

            // Point
            let point_timestamp_millis = DateTime.fromSQL(point_timestamp).toMillis();

            //  Substract first month from timestamp_millis last_month_millis
            let start = 0;
            let point = point_timestamp_millis - this.first_month_millis;
            let end = this.last_month_millis - this.first_month_millis;

            // console.log('Points:', start, point, end);

            // Point is before the first month
            if(point < start){
                console.log('Point smaller than start');
                return 0;
            }

            // Point is after last month
            if(point > end){
                console.log('Point larger than end');
                return 100;
            }

            return point / end * 100;

        },

        formatTimeStamp(timestamp){
            return DateTime.fromSQL(timestamp).toFormat('d LLL yyyy');
        },

        /**
         * Return true if item falls inside FROM - TO interval
         * @param timestamp
         * @returns {boolean}
         */
        isInInterval(timestamp){
            return (this.getXAxisAsPercent(timestamp) < 100 && this.getXAxisAsPercent(timestamp) > 0);
        },

        showExpected(rep){
            return (typeof rep.end_dates[0] == 'object' && rep.end_dates[0].timestamp !== this.report.end_date);
        }

    }
}
</script>

<style scoped>

</style>
