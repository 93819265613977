<template>

    <div id="blog_post_editor" v-if="uicontrol.ready">

        <div class="container">

            <div class="row">
                <div class="col-12 d-flex justify-content-between">
                    <h2>Editor</h2>
                    <div>
                        <button class="btn btn-primary" @click="save()">Save</button>
                        <button class="btn btn-secondary" @click="$emit('close')">Exit</button>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12">

                    <component v-for="(field, fKey) in template.fields"
                        :field="field"
                        :is="'rf_' + field.type"
                        :val="post_local.data[field.data_id]"
                        :tab="'default'"
                        :editable="true"
                        @update="setData($event)"
                        @save="save()"
                        :key="fKey">
                    </component>

                </div>
            </div>

        </div>

    </div>

</template>

<script>

    import rf_image from '@/components/sections/reusable/field_types/Image';
    import rf_input_text from '@/components/sections/reusable/field_types/InputText';
    import rf_textarea from '@/components/sections/reusable/field_types/TextArea';
    import rf_messaging_channel from '@/components/sections/reusable/field_types/MessagingChannel';
    import rf_ordered_list from '@/components/sections/reusable/field_types/OrderedList';

    export default {

        name: "BlogPostEditor",

        props : [
          'post', 'category_name', 'category_slug', 'category_id'
        ],

        components: {
            rf_image,
            rf_input_text,
            rf_textarea,
            rf_messaging_channel,
            rf_ordered_list
        },

        data() {
            return {
                post_local : {
                    data : {}
                },
                templates: [
                    {
                        "name" : "Suggested News",
                        "category-slug" : "suggested-news",
                        "fields": [
                            {
                                "label": "Title",
                                "type": "input_text",
                                "data_id": "title"
                            },
                            {
                                "label": "Short description",
                                "type": "input_text",
                                "data_id": "short_description"
                            },
                            {
                                "label": "Content",
                                "type": "textarea",
                                "data_id": "content"
                            },
                            {
                                "label": "Image",
                                "type": "image",
                                "data_id": "image"
                            }
                        ],
                    },
                    {
                        "name" : "Weekly Update",
                        "category-slug" : "weekly-update",
                        "fields": [
                            {
                                "label": "Title",
                                "type": "input_text",
                                "data_id": "title"
                            },
                            {
                                "label": "Short description",
                                "type": "input_text",
                                "data_id": "short_description"
                            },
                            {
                                "label": "Content",
                                "type": "textarea",
                                "data_id": "content"
                            },
                            {
                                "label": "Image",
                                "type": "image",
                                "data_id": "image"
                            }
                        ],
                    },
                    {
                        "name" : "Science News",
                        "category-slug" : "science-news",
                        "fields": [
                            {
                                "label": "Title",
                                "type": "input_text",
                                "data_id": "title"
                            },
                            {
                                "label": "Short description",
                                "type": "input_text",
                                "data_id": "short_description"
                            },
                            {
                                "label": "Content",
                                "type": "textarea",
                                "data_id": "content"
                            },
                            {
                                "label": "Image",
                                "type": "image",
                                "data_id": "image"
                            }
                        ],
                    },
                    {
                        "name" : "Featured Articles",
                        "category-slug" : "featured-articles",
                        "fields": [
                            {
                                "label": "Title",
                                "type": "input_text",
                                "data_id": "title"
                            },
                            {
                                "label": "Short description",
                                "type": "input_text",
                                "data_id": "short_description"
                            },
                            {
                                "label": "Content",
                                "type": "textarea",
                                "data_id": "content"
                            },
                            {
                                "label": "Image",
                                "type": "image",
                                "data_id": "image"
                            }
                        ],
                    },
                    {
                        "name" : "Weekly Science Update",
                        "category-slug" : "weekly-science-update",
                        "fields": [
                            {
                                "label": "Title",
                                "type": "input_text",
                                "data_id": "title"
                            },
                            {
                                "label": "Short description",
                                "type": "input_text",
                                "data_id": "short_description"
                            },
                            {
                                "label": "Content",
                                "type": "textarea",
                                "data_id": "content"
                            },
                            {
                                "label": "Image",
                                "type": "image",
                                "data_id": "image"
                            }
                        ],
                    },
                    {
                        "name" : "Scientific Papers",
                        "category-slug" : "scientific-papers",
                        "fields": [
                            {
                                "label": "Title",
                                "type": "input_text",
                                "data_id": "title"
                            },
                            {
                                "label": "Short description",
                                "type": "input_text",
                                "data_id": "short_description"
                            },
                            {
                                "label": "Content",
                                "type": "textarea",
                                "data_id": "content"
                            },
                            {
                                "label": "Image",
                                "type": "image",
                                "data_id": "image"
                            }
                        ],
                    },
                    {
                        "name" : "Video Based News",
                        "category-slug" : "video-based-news",
                        "fields": [
                            {
                                "label": "Title",
                                "type": "input_text",
                                "data_id": "title"
                            },
                            {
                                "label": "Short description",
                                "type": "input_text",
                                "data_id": "short_description"
                            },
                            {
                                "label": "Content",
                                "type": "textarea",
                                "data_id": "content"
                            },
                            {
                                "label": "Image",
                                "type": "image",
                                "data_id": "image"
                            }
                        ],
                    },
                    {
                        "name" : "Milestones Achieved and Timelines",
                        "category-slug" : "milestones-achieved-and-timelines",
                        "fields": [
                            {
                                "label": "Title",
                                "type": "input_text",
                                "data_id": "title"
                            },
                            {
                                "label": "Short description",
                                "type": "input_text",
                                "data_id": "short_description"
                            },
                            {
                                "label": "Content",
                                "type": "textarea",
                                "data_id": "content"
                            },
                            {
                                "label": "Image",
                                "type": "image",
                                "data_id": "image"
                            }
                        ],
                    }
                ],
                uicontrol : {
                    ready : false
                }

            }
        },

        computed : {
            template(){
                return this.templates.find(item => item.category_slug = this.category_slug);
            }
        },

        mounted() {

            for (let field of this.template.fields) {

                // Set null property in this.post_local
                if (typeof this.post_local.data[field.data_id] == 'undefined')
                    this.post_local.data[field.data_id] = null;

            }

            if(this.post !== null){
                Object.assign(this.post_local, this.post);
                Object.assign(this.post_local.data, this.post.data);
            }

            this.uicontrol.ready = true;

        },

        methods: {

            // Work through all methods

            setData(event) {
                Vue.set(this.post_local.data, event.field.data_id, event.value);
                this.$forceUpdate();
            },

            save(event = null) {

                if (event)
                    event.preventDefault();

                if (this.post_local.id == undefined) {
                    this.store();
                } else {
                    this.update();
                }

            },

            store() {

                return axios.post(`/blog-post`, {
                    category_id: this.category_id,
                    data: this.post_local.data,
                }).then(r => {

                    // Append the slug (used for rendering correct template)
                    r.data.category_slug = this.category_slug;

                    this.$emit('insert_post', r.data);
                    this.$emit('close');

                });

            },

            update() {

                this.$emit('update_post', this.post_local);
                this.$emit('close');

                return axios.put(`/blog-post/${this.post_local.id}`, {
                    data: this.post_local.data
                });
            }


        }
    }

</script>

<style scoped>

</style>
