<template>
    <a class="result empty">
        <!--<h3 class="text-center" v-text="result.title"></h3>-->
        <h3>Empty</h3>
    </a>
</template>

<script>
    export default {
        name : 'EmptyResult',
        props : ['result'],
        data : function(){
            return {

            }
        }
    }
</script>
