<template>

    <form id="science-experiment-result" action="" v-if="uicontrol.ready" onkeydown="return event.key != 'Enter';">

        <div class="card experiment-title-wrapper">
            <div class="top">
                <div class="group">
                    <input class="form-control" name="result_title" id="result_title" type="text" placeholder="Title" v-model="title" v-if="editable" v-validate="'required|min:3'" data-vv-as="Title">
                    <h3 class="title" v-else>{{title}}</h3>
                    <span class="error_label text-danger">{{ errors.first('result_title') }}</span>
                </div>

                <div class="group" v-if="editable">

                    <div class="pr-1">
                        <flat-pickr v-model="start_date" :config="flatpickr_config" placeholder="Start Date"></flat-pickr>
                    </div>

                    <div class="pl-1">
                        <flat-pickr v-model="end_date" :config="flatpickr_config" placeholder="End date"></flat-pickr>
                    </div>

                </div>

                <div class="group">
                    <a class="btn btn-delete-exp ml-0 mr-1" @click="deleteResult()" v-if="Boolean(result.identifier) && editable">Delete</a>
                    <a v-if="editable && result.identifier" :href="`/dashboard/science/experiments/${experiment.identifier}/results/${result.identifier}`" class="btn btn-edit-exp">View result</a>
                    <button type="button" @click="save($event)" v-if="editable" class="btn btn-edit-exp" :class="{ loading : uicontrol.saving }">
                        <div data-loader="circle-side"></div>
                        {{ !Object.keys(result).length ? 'Create result' : 'Save'}}
                    </button>
                    <a v-if="!editable && is_admin && !Boolean(result.signed_by_user_id)" :href="`/dashboard/science/experiments/${experiment.identifier}/results/${result.identifier}/edit`" class="btn btn-edit-exp">Edit Result</a>
                </div>
            </div>


            <div class="bottom">
                <p><b>Author:</b> {{experiment.author.name}}</p>
                <p><b>Lab:</b> {{experiment.laboratory.country.name}}</p>
                <p><b>Start:</b> {{ result.start_date ? DateTime.fromSQL(result.start_date).toFormat('dd LLL yyyy') :
                    'n/a'}}</p>
                <p><b>End:</b> {{ result.end_date ? DateTime.fromSQL(result.end_date).toFormat('dd LLL yyyy') : 'n/a'}}
                </p>
            </div>
        </div>


        <div class="card-tabs pb-5" v-if="Object.keys(result).length">
            <!-- Result is empty object? -> we are on the create page -->
            <nav>
                <div class="nav nav-tabs" id="nav-tab" role="tablist">
                    <a v-for="(Htab, HtabKey) in template.tabs" class="nav-link" :key="HtabKey" :class="{ active : (uicontrol.active_tab == Htab.id || (uicontrol.active_tab == null && HtabKey == 0) ) }" :id="Htab.id" @click="uicontrol.active_tab = Htab.id; save($event)" aria-selected="true">{{Htab.name}}</a>
                </div>
            </nav>
            <div class="tab-content" id="nav-tabContent">
                <div class="tab-pane fade show active pt-2" :key="BtabKey" v-for="(Btab, BtabKey) in template.tabs" v-show="uicontrol.active_tab == Btab.id || (uicontrol.active_tab == null && BtabKey == 0)">
                    <component
                        :is="'rf_' + field.type" v-for="field in Btab.fields"
                        :result_identifier="result.identifier"
                        :tab="Btab"
                        :field="field"
                        :val="getData(Btab, field)"
                        :key="Btab.id + field.data_id"
                        :editable="editable"
                        :is_author="is_author"
                        :can_participate="can_participate"
                        @update="setData($event)"
                        @save="save()">
                    </component>
                </div>
            </div>
        </div>

    </form>

</template>

<script>

    import {DateTime} from "luxon";
    import rf_image from '@/components/sections/reusable/field_types/Image';
    import rf_input_text from '@/components/sections/reusable/field_types/InputText';
    import rf_textarea from '@/components/sections/reusable/field_types/TextArea';
    import rf_messaging_channel from '@/components/sections/reusable/field_types/MessagingChannel';
    import rf_ordered_list from '@/components/sections/reusable/field_types/OrderedList';
    import rf_select from '@/components/sections/reusable/field_types/Select';
    import rf_date from '@/components/sections/reusable/field_types/Date';
    import rf_file from '@/components/sections/reusable/field_types/File';

    import flatPickr from 'vue-flatpickr-component';
    import 'flatpickr/dist/flatpickr.css';

    '@/components/chat/Channel';

    export default {

        name: 'ExperimentResult',

        data: function () {
            return {
                title: null,
                start_date: null,
                end_date: null,
                flatpickr_config: {
                    dateFormat: "Y-m-d H:i",
                    altInput: true,
                    altFormat: "F j, Y"
                },
                data: {},
                uicontrol: {
                    active_tab: null,
                    ready: false,
                    saving: false
                },
                experiment_type_result_template: {
                    other : 'template_a',
                    preclinical_in_vivo: 'template_a',
                    preclinical_in_vitro: 'template_a',
                    clinical: 'template_b'
                },
                templates: {
                    template_a: {
                        "name": "Template A",
                        "tabs": [
                            {
                                "name": "Overview",
                                "id": "overview",
                                "fields": [
                                    {
                                        "label": "Subtitle",
                                        "type": "input_text",
                                        "data_id": "subtitle"
                                    },
                                    {
                                        "label": "Host",
                                        "type": "input_text",
                                        "data_id": "host"
                                    },
                                    {
                                        "label": "Virus",
                                        "type": "input_text",
                                        "data_id": "virus_name"
                                    },
                                    {
                                        "label": "Objective",
                                        "type": "textarea",
                                        "data_id": "objective"
                                    },
                                    {
                                        "label": "Study Design",
                                        "type": "textarea",
                                        "data_id": "study_design"
                                    },
                                    {
                                        "label": "Conditions",
                                        "type": "textarea",
                                        "data_id": "conditions"
                                    },
                                    {
                                        "label": "Virus Description",
                                        "type": "textarea",
                                        "data_id": "virus_description"
                                    },

                                ],
                            },
                            {
                                "name": "Chart",
                                "id": "chart",
                                "fields": [
                                    {
                                        "label": "Chart",
                                        "type": "image",
                                        "data_id": "chart_image"
                                    },
                                    {
                                        "label": "Comments",
                                        "type": "input_text",
                                        "data_id": "comments"
                                    },
                                ],
                            },
                            {
                                "name": "Data Table",
                                "id": "data_table",
                                "fields": [
                                    {
                                        "label": "Table",
                                        "type": "textarea",
                                        "data_id": "data_table"
                                    }
                                ],
                            },
                            {
                                "name": "Data Results",
                                "id": "data_results",
                                "fields": [
                                    {
                                        "label": "Results",
                                        "type": "textarea",
                                        "data_id": "data_results"
                                    }
                                ],
                            },
                            {
                                "name": "Comments",
                                "id": "comments",
                                "fields": [
                                    {
                                        "label": "Comments",
                                        "type": "messaging_channel",
                                        "data_id": null
                                    }
                                ],
                            },
                            {
                                "name": "Actions",
                                "id": "actions",
                                "fields": [
                                    {
                                        "label": "Actions",
                                        "type": "ordered_list",
                                        "data_id": "actions"
                                    }
                                ],
                            },

                        ]
                    },
                    template_b: {
                        "name": "Template B",
                        "tabs": [
                            {
                                "name": "Overview",
                                "id": "overview",
                                "fields": [
                                    {
                                        "label": "Phase",
                                        "type": "select",
                                        "data_id": "phase",
                                        "options": [
                                            {
                                                "label": "Select",
                                                "value": null
                                            },
                                            {
                                                "label": "1",
                                                "value": 1
                                            },
                                            {
                                                "label": "2",
                                                "value": 2
                                            },
                                            {
                                                "label": "2/3",
                                                "value": 23
                                            },
                                            {
                                                "label": "3",
                                                "value": 3
                                            },
                                            {
                                                "label": "4",
                                                "value": 4
                                            }
                                        ]
                                    },
                                    {
                                        "label": "Subtitle",
                                        "type": "input_text",
                                        "data_id": "subtitle"
                                    },
                                    {
                                        "label": "Host",
                                        "type": "input_text",
                                        "data_id": "host"
                                    },
                                    {
                                        "label": "Virus",
                                        "type": "input_text",
                                        "data_id": "virus_name"
                                    },
                                    {
                                        "label": "Primary Objective",
                                        "type": "textarea",
                                        "data_id": "primary_objective"
                                    },
                                    {
                                        "label": "Secondary Objective",
                                        "type": "textarea",
                                        "data_id": "secondary_objective"
                                    },
                                    {
                                        "label": "Study Design",
                                        "type": "textarea",
                                        "data_id": "study_design"
                                    },
                                    {
                                        "label": "Statistical Analysis Plan",
                                        "type": "textarea",
                                        "data_id": "statistical_analysis_plan"
                                    }
                                ],
                            },
                            {
                                "name": "Timings",
                                "id": "timings",
                                "fields": [
                                    {
                                        "label": "Timeline: First Patient, First Visit",
                                        "type": "date",
                                        "data_id": "timeline_first_patient_first_visit"
                                    },
                                    {
                                        "label": "Timeline: Last Patient Visit",
                                        "type": "date",
                                        "data_id": "timeline_last_patient_visit"
                                    },
                                    {
                                        "label": "Database lock",
                                        "type": "date",
                                        "data_id": "database_lock"
                                    },
                                    {
                                        "label": "Study report",
                                        "type": "date",
                                        "data_id": "study_report"
                                    },
                                ],
                            },
                            {
                                "name": "Chart",
                                "id": "chart",
                                "fields": [
                                    {
                                        "label": "Primary Result",
                                        "type": "textarea",
                                        "data_id": "primary_result"
                                    },
                                    {
                                        "label": "Secondary Result",
                                        "type": "textarea",
                                        "data_id": "secondary_result"
                                    }
                                ],
                            },
                            {
                                "name": "Data Table",
                                "id": "data_table",
                                "fields": [
                                    {
                                        "label": "Results",
                                        "type": "textarea",
                                        "data_id": "results"
                                    },
                                    {
                                        "label": "Upload CSV",
                                        "type": "file",
                                        "data_id": "upload_csv"
                                    },
                                ],
                            },
                            {
                                "name": "Conclusion",
                                "id": "conclusion",
                                "fields": [
                                    {
                                        "label": "Conclusion",
                                        "type": "textarea",
                                        "data_id": "conclusion"
                                    }
                                ],
                            },
                            {
                                "name": "Comments",
                                "id": "comments",
                                "fields": [
                                    {
                                        "label": "Comments",
                                        "type": "messaging_channel",
                                        "data_id": null
                                    }
                                ],
                            },
                            {
                                "name": "Actions",
                                "id": "actions",
                                "fields": [
                                    {
                                        "label": "Actions",
                                        "type": "ordered_list",
                                        "data_id": "actions"
                                    }
                                ],
                            }
                        ]
                    }
                },
                DateTime: DateTime
            }
        },

        components: {
            rf_image,
            rf_input_text,
            rf_textarea,
            rf_messaging_channel,
            rf_ordered_list,
            flatPickr,
            rf_select,
            rf_date,
            rf_file
        },

        props: [
            'experiment', 'result', 'editable', 'can_participate', 'is_admin', 'is_author'
        ],

        computed: {
            template() {
                return this.templates[this.experiment_type_result_template[this.experiment.type]];
            }
        },

        mounted() {

            this.buildDataFromTemplate();

            this.title = this.result.title;
            this.start_date = this.result.start_date;
            this.end_date = this.result.end_date;

            if (this.result)
                this.loadDataFromResult();

            this.uicontrol.ready = true;

            if (window.location.hash == '#comments')
                this.uicontrol.active_tab = 'comments';

        },

        methods: {

            buildDataFromTemplate() {

                // Iterate tabs
                for (let tab of this.template.tabs) {
                    for (let field of tab.fields) {
                        // Set null property in this.data
                        if (typeof this.data[this.composePropertyName(tab, field)] == 'undefined')
                            this.data[this.composePropertyName(tab, field)] = null;
                    }
                }

            },

            loadDataFromResult() {
                Object.assign(this.data, this.result.data);
            },

            getData(tab, field) {
                return this.data[this.composePropertyName(tab, field)];
            },

            setData(event) {
                Vue.set(this.data, this.composePropertyName(event.tab, event.field), event.value);
                this.$forceUpdate();
            },

            composePropertyName(tab, field) {
                return _.replace(tab.name + '_' + field.data_id, ' ', '_')
            },

            save(event = null) {

                console.log(event);

                if (event)
                    event.preventDefault();

                if (_.isEmpty(this.result)) {
                    this.store();
                } else {
                    this.update();
                }

            },

            deleteResult() {

                var vm = this;

                Swal.fire({
                    title: 'Delete result?',
                    text: 'This will delete all data from this result. This action cannot be undone',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes'
                }).then((result) => {
                    if (result.isConfirmed) {

                        axios.delete('/dashboard/science/experiments/' + vm.experiment.identifier + '/results/' + vm.result.identifier).then(function(){
                            // window.location.href = '/dashboard/science/experiments/' + vm.experiment.identifier;
                        });
                    }
                });

            },

            store() {


                this.$validator.validate().then(valid => {

                    if (!valid) return;


                    axios.post(`/dashboard/science/experiments/${this.experiment.identifier}/results`, {
                        title: this.title,
                        start_date: this.start_date,
                        end_date: this.end_date,
                        data: this.data
                    }).then(response => {

                        // Go to edit page
                        if (response.data.success)
                            window.location.href = baseurl + '/dashboard/science/experiments/' + this.experiment.identifier + '/results/' + response.data.result_identifier + '/edit';

                    });


                });


            },

            update() {

                this.$validator.validate().then(valid => {

                    if (!valid) return;

                    this.uicontrol.saving = true;

                    axios.put(`/dashboard/science/experiments/${this.experiment.identifier}/results/${this.result.identifier}`, {
                        title: this.title,
                        start_date: this.start_date,
                        end_date: this.end_date,
                        data: this.data
                    }).then(response => {
                        this.uicontrol.saving = false;
                    });


                });

            }


        }

    }

</script>
